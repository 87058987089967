import React, { useEffect, useState } from "react";
import {
    Grid,
    Typography,
    Pagination,
    Switch,
    Chip,
    Modal,
    Box,
    TextField,
    Button,
} from "@mui/material";
import Swal from "sweetalert2";
import styled from "styled-components";
import { post_data } from "../../../api";
import Loader from "../../../website/Components/Loader";

const ServiceBox = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  overflow: hidden;
`;

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 350,
    bgcolor: "background.paper",
    border: "none",
    outline: "none",
    boxShadow: 24,
    p: "2%",
    borderRadius: "10px",
};

export default function ServiceAreasTable({ tableHeader, data, setLoader, loader, getAllServiceArea }) {
    const table_div = {
        padding: "3%",
        borderRadius: 10,
        border: "1px solid #F1F1F2",
        boxShadow: "0px 3px 4px 0 #00000008",
        background: "white",
    };

    const table_header_title = {
        margin: 0,
        fontWeight: 600,
        fontSize: 14,
        color: "#3F4254",
    };

    const table_header_div = {
        width: "100%",
        margin: "1% 0",
        padding: "1% 0",
        display: "flex",
        alignItems: "center",
    };

    const table_row_font = { margin: 0, fontWeight: 500, fontSize: 14 };

    const [errors, setErrors] = useState({});
    const [pincodes, setPincodes] = useState([]);
    const [editArea, setEditArea] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [updateID, setUpdateID] = useState('');
    const [disabled, setDisabled] = useState(false);

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    })
    const handleError = (label, value) => {
        setErrors((prev) => ({ ...prev, [label]: value }));
    };

    function validate() {
        let error = false;

        if (pincodes.length === 0) {
            error = true;
            handleError("pincodes", "Pincodes are required");
        }
        return error;
    }

    const displayTableHeader = () => {
        return (
            <div style={{ width: "100%" }}>
                <Grid container spacing={0} style={table_header_div}>
                    {tableHeader.map((header, index) => (
                        <Grid
                            key={index}
                            item
                            xs={header?.grid}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: index === 0 || index === 1 ? "left" : "center",
                            }}
                        >
                            <h3 style={table_header_title}>{header?.title}</h3>
                        </Grid>
                    ))}
                </Grid>
            </div>
        );
    };

    const handleEditClick = (item) => {
        setEditArea(true);
        setPincodes(item?.pincodes);
        setUpdateID(item?._id);
    };

    const handleClose = () => {
        setEditArea(false);
    };

    const handleUpdateArea = async () => {
        setDisabled(true);

        const data = await post_data(`service-area/update-service-area/${updateID}`, { pincodes });
        if (data?.status) {
            Toast.fire({
                title: "Service Area Updated!",
                color: "green",
                icon: "success"
            });
            setEditArea(false);
            getAllServiceArea();
        } else {
            Toast.fire({
                title: "Something Went Wrong!",
                color: "red",
                icon: "warning"
            });
        }
        setDisabled(false);

    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter" || event.key === ",") {
            event.preventDefault();
            const trimmedValue = inputValue.trim();
            if (trimmedValue && !pincodes.includes(trimmedValue)) {
                setPincodes((prev) => [...prev, trimmedValue]);
                setInputValue("");
                setErrors({ ...errors, pincodes: "" });
            }
        }
    };

    const handleDeleteChip = (chipToDelete) => {
        setPincodes((chips) => chips.filter((chip) => chip !== chipToDelete));
    };


    const displayAllHeader = () => {
        return (
            <div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "right",
                        cursor: "pointer",
                    }}
                >
                    <span
                        style={{ display: "flex", alignItems: "center", gap: "3px", color: "#34eb52" }}
                    >
                        <Modal open={editArea} onClose={handleClose}>
                            <Box sx={style}>
                                <Typography sx={{ mt: 2 }}>
                                    <ServiceBox>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    variant="outlined"
                                                    label="Pincodes"
                                                    value={inputValue}
                                                    fullWidth
                                                    error={Boolean(errors.pincodes)}
                                                    helperText={errors.pincodes}
                                                    sx={{ mt: 2 }}
                                                    onChange={(e) => {
                                                        setInputValue(e.target.value);
                                                    }}
                                                    onKeyDown={handleKeyDown}
                                                    onFocus={() => {
                                                        setErrors({ ...errors, pincodes: "" });
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sx={{ mt: 2 }}>
                                                {pincodes?.map((pincode, index) => (
                                                    <Chip
                                                        key={index}
                                                        label={pincode}
                                                        onDelete={() => handleDeleteChip(pincode)}
                                                        sx={{
                                                            margin: "2px",
                                                            backgroundColor: "#f1f1f1",
                                                            borderRadius: "16px",
                                                        }}
                                                    />
                                                ))}
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    disabled={disabled}
                                                    sx={{
                                                        padding: "2.5% 0",
                                                        background: "#5DB761",
                                                        "&:hover": { backgroundColor: "#5DB761" },
                                                    }}
                                                    onClick={handleUpdateArea}
                                                >
                                                    Update Service Area
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </ServiceBox>
                                </Typography>
                            </Box>
                        </Modal>
                    </span>
                </div>

                {displayTableHeader()}
                {loader ? (
                    <Grid
                        item
                        xs={12}
                        style={{ display: "flex", justifyContent: "center", marginTop: "5%" }}
                    >
                        <Loader />
                    </Grid>
                ) : data?.length > 0 ? (
                    data?.map((item, i) => (
                        <div style={{ width: "100%" }} key={i}>
                            <Grid
                                container
                                spacing={0}
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    padding: "0.7% 0",
                                    alignItems: "center",
                                    borderTop: "1px dashed gainsboro",
                                }}
                            >
                                <Grid item xs={1}>
                                    <h3 style={{ ...table_row_font, color: "#3F4254", margin: 0, padding: 0 }}>
                                        {1 + i}
                                    </h3>
                                </Grid>

                                <Grid item xs={3} style={{ display: "flex", alignItems: "center" }}>
                                    <h3 style={{ ...table_row_font, color: "#3F4254", margin: 0, padding: 0 }}>
                                        {item?.city}
                                    </h3>
                                </Grid>

                                <Grid
                                    item
                                    xs={4}
                                    style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                                >
                                    <h3 style={{ ...table_row_font, color: "#3F4254", margin: 0, padding: 0 }}>
                                        {item?.state}
                                    </h3>
                                </Grid>

                                <Grid
                                    item
                                    xs={2}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <h3 style={{ ...table_row_font, color: "#3F4254", margin: 0, padding: 0 }}>
                                        {item?.pincodes?.length} {item?.pincodes?.length > 1 ? 'Pincodes' : 'Pincode'}
                                    </h3>
                                </Grid>

                                <Grid
                                    item
                                    xs={2}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: "10px",
                                    }}
                                >
                                    <img
                                        onClick={() => handleEditClick(item)}
                                        src="/images/edit-img.svg"
                                        style={{ cursor: "pointer" }}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    ))
                ) : (
                    <div>
                        <Grid
                            item
                            xs={12}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: "#3F4254",
                                marginTop: "5%",
                            }}
                        >
                            No Service Area Found...
                        </Grid>
                    </div>
                )}
            </div>
        );
    };

    return <div style={table_div}>{displayAllHeader()}</div>;
}
