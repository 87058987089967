import React from 'react';
import { useMediaQuery } from '@mui/material';

const AppPromotion = () => {

    const isDesktop = useMediaQuery('(min-width: 768px)');

    const styles = {
        container: {
            display: 'flex',
            flexDirection: isDesktop ? 'row' : 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: isDesktop ? '2% 3%' : '6% 2%',
            // backgroundColor: "linear-gradient(to left, #5DB761, #29512B)",
            borderRadius: 30,
            color: '#fff',
            width: isDesktop ? '70%' : '90%',
            margin: '10% auto 6%',
            textAlign: isDesktop ? 'left' : 'center',
        },
        textContainer: {
            flex: 1,
            display: 'flex',
            justifyContent: 'start',
            flexDirection: 'column',
            order: isDesktop ? 1 : 2,
            marginRight: isDesktop ? '20px' : '0',
        },
        heading: {
            fontSize: isDesktop ? 45 : 24,
            fontWeight: '700',
            textTransform: 'uppercase',
            marginTop: 0,
            marginBottom: 20,
        },
        subHeading: {
            fontSize: '16px',
            marginBottom: '20px',
        },
        imageContainer: {
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            order: isDesktop ? 2 : 1
        },
        appImage: {
            // maxWidth: '100%',
            width: isDesktop ? 450 : 220,
            height: 'auto',
            marginTop: isDesktop ? '-25%' : '',
            marginBottom: isDesktop ? '' : '15%'
        },
        storeIcons: {
            display: 'flex',
            justifyContent: isDesktop ? 'flex-start' : 'center',
            gap: '10px',
        },
        storeIcon: {
            width: '150px',
            height: 'auto',
        }
    };

    return (
        <div style={styles.container} className='app-promo'>
            <div style={styles.textContainer}>
                <h2 style={styles.heading}>We are Better on our Mobille APP</h2>
                <div style={styles.storeIcons}>
                    <img src="/images/app-store.svg" alt="App Store" style={styles.storeIcon} />
                    <img src="/images/google-play.svg" alt="Google Play Store" style={styles.storeIcon} />
                </div>
            </div>
            <div style={styles.imageContainer}>
                <img src={isDesktop ? "/images/app-mockup.svg" : "/images/app-mockup-mobile.svg"} alt="App" style={styles.appImage} />
            </div>
        </div>
    );
}

export default AppPromotion;