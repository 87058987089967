import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const backdropStyle = {
    backgroundColor: '#000000c5',
};

export default function AppPromoModal({
    open,
    setOpen
}) {

    const theme = useTheme();
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'));
    const matches_md = useMediaQuery(theme.breakpoints.down('md'));

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: matches_md ? '85%' : 500,
        bgcolor: 'white',
        boxShadow: 24,
        border: '1px solid white',
        borderRadius: matches_md ? 3 : 4,
        padding: '2% 4% 3%',
    };

    const app_modal_styles = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    }

    const image_styles = {
        marginTop: matches_md ? '3%' : '',
        width: matches_md ? 130 : 170
    }

    const handleClose = () => setOpen(false);

    const appPromotion = () => {
        return (
            <>
                <div style={app_modal_styles}>
                    <img src="/images/app-mockup-mobile.svg" style={{ width: matches_md ? 300 : 250 }} />
                    <h2 className='global-h2' style={{ textAlign: 'center', fontSize: matches_md ? 27 : 33, marginTop: '3%' }}>We are better on our Mobile App!</h2>
                    {/* <p className='about-para' style={{ textAlign: 'center', margin: '2% auto 3%', width: matches_md ? '100%' : '80%' }}>Stay up-to-date with our home services, book directly from your mobile app</p> */}
                    <div style={{ display: 'flex', gap: '3%', width: '100%', justifyContent: 'center' }}>
                        <img src={`/images/google-play.svg`} style={image_styles} />
                        <img src={`/images/app-store.svg`} style={image_styles} />
                    </div>
                </div>
            </>
        )
    }

    return (
        <div>
            <Modal
                BackdropProps={{
                    style: backdropStyle,
                }}
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    {appPromotion()}
                </Box>
            </Modal>
        </div>
    );
}
