import { useMediaQuery } from '@mui/material';
import React from 'react';

const testimonials = [
    {
        name: "Kadin Torff",
        handle: "@darrels",
        text: "Wilson was fantastic! Easy to work with, efficient, and went above and beyond when the task ended up being much larger/longer than we anticipated.",
        service: "Delivery",
        image: "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" // Replace with actual image path
    },
    {
        name: "Lincoln Herwitz",
        handle: "@lesslie",
        text: "Essra was a pleasure to work with. Punctual, friendly, helpful and really knows how to pack a storage unit for maximum efficiency.",
        service: "Help Moving",
        image: "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" // Replace with actual image path
    },
    {
        name: "Jakob Saris",
        handle: "@jennywilson",
        text: "Dmitriy was great! Was fast to respond to the job, worked quickly but professionally, and had all the appropriate tools.",
        service: "Mounting",
        image: "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" // Replace with actual image path
    },
    {
        name: "Carla Rosser",
        handle: "@kristinwatson2",
        text: "Hermann did a fantastic job installing a new AC unit in our house. His communication was excellent and he did everything efficiently.",
        service: "Home Repairs",
        image: "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" // Replace with actual image path
    }
];

const Testimonials = () => {

    const isDesktop = useMediaQuery('(min-width: 768px)');

    return (
        <div className="testimonials-section">
            <h2 style={{ fontWeight: 600, color: '#000', fontSize: isDesktop ? 35 : 24, }}>Our customers are living life with a <span className="highlight">smile on their faces!</span></h2>
            <div className="testimonials">
                {testimonials?.map((testimonial, index) => (
                    <div className="testimonial" key={index}>
                        <img src={testimonial.image} alt={testimonial.name} className="testimonial-image" />
                        <div className="testimonial-content">
                            <p className="testimonial-name">{testimonial.name} <span className="testimonial-handle">{testimonial.handle}</span></p>
                            <p className="testimonial-text">"{testimonial.text}"</p>
                            <p className="testimonial-service">{testimonial.service}</p>
                        </div>
                    </div>
                ))}
            </div>
            <a href="#all-reviews" className="reviews-link">Check all 2,157 reviews</a>
        </div>
    );
};

export default Testimonials;