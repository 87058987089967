import React, { useEffect, useState } from "react"
import { Grid } from "@mui/material"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import Sidebar from "../Components/Sidebar";
import TopBar from "../Components/TopBar";
import Sales from "./Sales";
import DashboardComponent from "../Components/DashboardComponent";
import Customers from "./Customers";
import Vendors from "./Vendors";
import { MdDashboard, MdPeople, MdSettings, MdOutlineSubtitles } from "react-icons/md";
import { IoIosLock } from "react-icons/io";
import Services from "./Services";
import SubServices from "./SubService";
import AdminProfile from "./AdminProfile";
import Booking from "./Booking";
import { MdOutlineDiscount } from "react-icons/md";
import { MdOutlineQuestionAnswer } from "react-icons/md";
import { MdOutlineReviews } from "react-icons/md";
import { MdOutlineMail } from "react-icons/md";
import { TbCreditCardRefund } from "react-icons/tb";
import { VscFeedback } from "react-icons/vsc";
import { MdCardMembership } from "react-icons/md";
import Reviews from "./Reviews";
import Profession from "./Profession";
import PlusMembership from "./PlusMembership";
import { BsGraphUpArrow } from "react-icons/bs";
import Refund from "./Refund";
import { HiCalendar } from "react-icons/hi";
import Newsletter from "./Newsletter";
import Faq from "./Faq";
import Coupon from "./Coupon";
import MembershipCustomers from "./MembershipCustomers";
import Feedbacks from "./Feedbacks";
import VendorFaq from "./VendorFaq";
import { HiMiniWrenchScrewdriver } from "react-icons/hi2";
import { MdLocationPin } from "react-icons/md";
import ServiceAreas from "./ServiceAreas";
import Permissions from "./Permissions"
import SubAdmin from "./SubAdmins"
import { FaUserCircle } from "react-icons/fa";
import { BsCart2 } from "react-icons/bs";
import CustomersCart from "./CustomerCart";
import { useSelector } from "react-redux";
import PermissionDenied from "../../../website/Components/PermissionDenied";
import { MdHomeRepairService } from "react-icons/md";
import { BiSolidUserPin } from "react-icons/bi";

export default function Dashboard({ socket }) {

    const isDesktop = useMediaQuery('(min-width: 768px)');
    const { user_data } = useSelector(state => state.user);
    const [permissions, setPermissions] = useState([])

    function getPermissionKeys(arr) {
        return arr?.map(permission => permission.permission_key);
    }

    useEffect(() => {
        const sub_admin_permissions = getPermissionKeys(user_data?.permissions)

        if (sub_admin_permissions) {
            setPermissions(sub_admin_permissions)
        }
    }, [user_data])

    const content_section = {
        height: '90.8vh',
        padding: '0 2%',
        background: '#F8F8F8',
        overflowY: 'auto',
    }

    const menu_items = [
        {
            title: 'Dashboard',
            link: '/admin/dashboard',
            icon: <MdDashboard style={{ color: 'white' }} />,
        },
        {
            title: 'Sales',
            link: '/admin/dashboard/sales',
            icon: <BsGraphUpArrow style={{ color: 'white' }} />,
        },
        {
            title: 'Bookings',
            link: '/admin/dashboard/bookings',
            icon: <HiCalendar style={{ color: 'white' }} />,
        },
        {
            title: 'Customers',
            link: '/admin/dashboard/customers',
            icon: <MdPeople style={{ color: 'white' }} />,
        },
        {
            title: 'Customers Cart',
            link: '/admin/dashboard/customers-cart',
            icon: <BsCart2 style={{ color: 'white' }} />,
        },
        {
            title: 'Refunds',
            link: '/admin/dashboard/refunds',
            icon: <TbCreditCardRefund style={{ color: 'white' }} />,
        },
        {
            title: 'Vendors',
            link: '/admin/dashboard/vendors',
            icon: <BiSolidUserPin style={{ color: 'white' }} />,
        },
        {
            title: 'Professions',
            link: '/admin/dashboard/professions',
            icon: <MdHomeRepairService style={{ color: 'white' }} />,
        },
        {
            title: 'Services',
            link: '/admin/dashboard/services',
            icon: <HiMiniWrenchScrewdriver style={{ color: 'white' }} />,
        },
        {
            title: 'Sub Services',
            link: '/admin/dashboard/sub-service',
            icon: <MdOutlineSubtitles style={{ color: 'white' }} />,
        },
        {
            title: 'Sub Admins',
            link: '/admin/dashboard/sub-admins',
            icon: <FaUserCircle style={{ color: 'white' }} />,
        },
        {
            title: 'Permissions',
            link: '/admin/dashboard/permissions',
            icon: <IoIosLock style={{ color: 'white' }} />,
        },
        {
            title: 'Service Areas',
            link: '/admin/dashboard/service-areas',
            icon: <MdLocationPin style={{ color: 'white' }} />,
        },
        {
            title: 'Membership Customers',
            link: '/admin/dashboard/membership-customers',
            icon: <MdPeople style={{ color: 'white' }} />,
        },
        {
            title: 'Reviews',
            link: '/admin/dashboard/reviews',
            icon: <MdOutlineReviews style={{ color: 'white' }} />,
        },
        {
            title: 'Coupons',
            link: '/admin/dashboard/coupons',
            icon: <MdOutlineDiscount style={{ color: 'white' }} />,
        },
        {
            title: 'Newsletters',
            link: '/admin/dashboard/newsletters',
            icon: <MdOutlineMail style={{ color: 'white' }} />,
        },
        {
            title: 'FAQ',
            link: '/admin/dashboard/faqs',
            icon: <MdOutlineQuestionAnswer style={{ color: 'white' }} />,
        },
        {
            title: 'Vendors FAQ',
            link: '/admin/dashboard/vendors-faqs',
            icon: <MdOutlineQuestionAnswer style={{ color: 'white' }} />,
        },
        {
            title: 'Feedbacks',
            link: '/admin/dashboard/feedbacks',
            icon: <VscFeedback style={{ color: 'white' }} />,
        },
        {
            title: 'Plus Membership',
            link: '/admin/dashboard/membership',
            icon: <MdCardMembership style={{ color: 'white' }} />,
        },
        {
            title: 'Settings',
            link: '/admin/dashboard/settings',
            icon: <MdSettings style={{ color: 'white' }} />,
        }
    ]


    return (
        <>
            <div style={{ position: 'relative' }}>
                {
                    isDesktop ?
                        <>
                            <Grid container spacing={0}>
                                <Grid item md={1.6} style={{ position: 'sticky', top: 0, height: '100vh' }}>
                                    <Sidebar
                                        menu_items={menu_items}
                                    />
                                </Grid>
                                <Grid item md={10.4} style={{ overflowY: 'auto' }} className="dashboard_content_grid">
                                    <TopBar socket={socket} permissions={permissions} />
                                    <div style={content_section} className="content-section">
                                        <Routes>

                                            {
                                                user_data?.is_sub_admin ? (
                                                    permissions?.includes('dashboard') ? (
                                                        <Route element={<DashboardComponent socket={socket} permissions={permissions} />} path="/" />
                                                    ) : (
                                                        <Route element={<PermissionDenied title='You have no Permission to view this page' />} path="/" />
                                                    )
                                                ) : (
                                                    <Route element={<DashboardComponent socket={socket} permissions={permissions} />} path="/" />
                                                )
                                            }


                                            {
                                                user_data?.is_sub_admin ? (
                                                    permissions?.includes('sales') ? (
                                                        <Route element={<Sales />} path="/sales" />
                                                    ) : (
                                                        <Route element={<PermissionDenied title='You have no Permission to view this page' />} path="/sales" />
                                                    )
                                                ) : (
                                                    <Route element={<Sales />} path="/sales" />
                                                )
                                            }


                                            {
                                                user_data?.is_sub_admin ? (
                                                    permissions?.includes('customers') ? (
                                                        <Route element={<Customers />} path="/customers" />
                                                    ) : (
                                                        <Route element={<PermissionDenied title='You have no Permission to view this page' />} path="/customers" />
                                                    )
                                                ) : (
                                                    <Route element={<Customers />} path="/customers" />
                                                )
                                            }


                                            {
                                                user_data?.is_sub_admin ? (
                                                    permissions?.includes('vendors') ? (
                                                        <Route element={<Vendors />} path="/vendors" />
                                                    ) : (
                                                        <Route element={<PermissionDenied title='You have no Permission to view this page' />} path="/vendors" />
                                                    )
                                                ) : (
                                                    <Route element={<Vendors />} path="/vendors" />
                                                )
                                            }


                                            {
                                                user_data?.is_sub_admin ? (
                                                    <Route element={<PermissionDenied title='You have no Permission to view this page' />} path="/permissions" />
                                                ) : (
                                                    <Route element={<Permissions />} path="/permissions" />
                                                )
                                            }


                                            {
                                                user_data?.is_sub_admin ? (
                                                    <Route element={<PermissionDenied title='You have no Permission to view this page' />} path="/sub-admins" />
                                                ) : (
                                                    <Route element={<SubAdmin />} path="/sub-admins" />
                                                )
                                            }


                                            {
                                                user_data?.is_sub_admin ? (
                                                    permissions?.includes('services') ? (
                                                        <Route element={<Services />} path="/services" />
                                                    ) : (
                                                        <Route element={<PermissionDenied title='You have no Permission to view this page' />} path="/services" />
                                                    )
                                                ) : (
                                                    <Route element={<Services />} path="/services" />
                                                )
                                            }


                                            {
                                                user_data?.is_sub_admin ? (
                                                    permissions?.includes('sub-services') ? (
                                                        <Route element={<SubServices />} path="/sub-service" />
                                                    ) : (
                                                        <Route element={<PermissionDenied title='You have no Permission to view this page' />} path="/sub-service" />
                                                    )
                                                ) : (
                                                    <Route element={<SubServices />} path="/sub-service" />
                                                )
                                            }


                                            {
                                                user_data?.is_sub_admin ? (
                                                    permissions?.includes('bookings') ? (
                                                        <Route element={<Booking socket={socket} />} path="/bookings" />
                                                    ) : (
                                                        <Route element={<PermissionDenied title='You have no Permission to view this page' />} path="/bookings" />
                                                    )
                                                ) : (
                                                    <Route element={<Booking socket={socket} />} path="/bookings" />
                                                )
                                            }


                                            {
                                                user_data?.is_sub_admin ? (
                                                    permissions?.includes('reviews') ? (
                                                        <Route element={<Reviews />} path="/reviews" />
                                                    ) : (
                                                        <Route element={<PermissionDenied title='You have no Permission to view this page' />} path="/reviews" />
                                                    )
                                                ) : (
                                                    <Route element={<Reviews />} path="/reviews" />
                                                )
                                            }


                                            {
                                                user_data?.is_sub_admin ? (
                                                    permissions?.includes('professions') ? (
                                                        <Route element={<Profession />} path="/professions" />
                                                    ) : (
                                                        <Route element={<PermissionDenied title='You have no Permission to view this page' />} path="/professions" />
                                                    )
                                                ) : (
                                                    <Route element={<Profession />} path="/professions" />
                                                )
                                            }

                                            {
                                                user_data?.is_sub_admin ? (
                                                    permissions?.includes('plus_membership') ? (
                                                        <Route element={<PlusMembership />} path="/membership" />
                                                    ) : (
                                                        <Route element={<PermissionDenied title='You have no Permission to view this page' />} path="/membership" />
                                                    )
                                                ) : (
                                                    <Route element={<PlusMembership />} path="/membership" />
                                                )
                                            }


                                            {
                                                user_data?.is_sub_admin ? (
                                                    permissions?.includes('refunds') ? (
                                                        <Route element={<Refund />} path="/refunds" />
                                                    ) : (
                                                        <Route element={<PermissionDenied title='You have no Permission to view this page' />} path="/refunds" />
                                                    )
                                                ) : (
                                                    <Route element={<Refund />} path="/refunds" />
                                                )
                                            }


                                            {
                                                user_data?.is_sub_admin ? (
                                                    permissions?.includes('newsletters') ? (
                                                        <Route element={<Newsletter />} path="/newsletters" />
                                                    ) : (
                                                        <Route element={<PermissionDenied title='You have no Permission to view this page' />} path="/newsletters" />
                                                    )
                                                ) : (
                                                    <Route element={<Newsletter />} path="/newsletters" />
                                                )
                                            }


                                            {
                                                user_data?.is_sub_admin ? (
                                                    permissions?.includes('faq') ? (
                                                        <Route element={<Faq />} path="/faqs" />
                                                    ) : (
                                                        <Route element={<PermissionDenied title='You have no Permission to view this page' />} path="/faqs" />
                                                    )
                                                ) : (
                                                    <Route element={<Faq />} path="/faqs" />
                                                )
                                            }


                                            {
                                                user_data?.is_sub_admin ? (
                                                    permissions?.includes('vendors_faq') ? (
                                                        <Route element={<VendorFaq />} path="/vendors-faqs" />
                                                    ) : (
                                                        <Route element={<PermissionDenied title='You have no Permission to view this page' />} path="/vendors-faqs" />
                                                    )
                                                ) : (
                                                    <Route element={<VendorFaq />} path="/vendors-faqs" />
                                                )
                                            }


                                            {
                                                user_data?.is_sub_admin ? (
                                                    permissions?.includes('coupons') ? (
                                                        <Route element={<Coupon />} path="/coupons" />
                                                    ) : (
                                                        <Route element={<PermissionDenied title='You have no Permission to view this page' />} path="/coupons" />
                                                    )
                                                ) : (
                                                    <Route element={<Coupon />} path="/coupons" />
                                                )
                                            }


                                            {
                                                user_data?.is_sub_admin ? (
                                                    permissions?.includes('membership_customers') ? (
                                                        <Route element={<MembershipCustomers />} path="/membership-customers" />
                                                    ) : (
                                                        <Route element={<PermissionDenied title='You have no Permission to view this page' />} path="/membership-customers" />
                                                    )
                                                ) : (
                                                    <Route element={<MembershipCustomers />} path="/membership-customers" />
                                                )
                                            }


                                            {
                                                user_data?.is_sub_admin ? (
                                                    permissions?.includes('feedbacks') ? (
                                                        <Route element={<Feedbacks />} path="/feedbacks" />
                                                    ) : (
                                                        <Route element={<PermissionDenied title='You have no Permission to view this page' />} path="/feedbacks" />
                                                    )
                                                ) : (
                                                    <Route element={<Feedbacks />} path="/feedbacks" />
                                                )
                                            }


                                            {
                                                user_data?.is_sub_admin ? (
                                                    permissions?.includes('service_areas') ? (
                                                        <Route element={<ServiceAreas />} path="/service-areas" />
                                                    ) : (
                                                        <Route element={<PermissionDenied title='You have no Permission to view this page' />} path="/service-areas" />
                                                    )
                                                ) : (
                                                    <Route element={<ServiceAreas />} path="/service-areas" />
                                                )
                                            }


                                            {
                                                user_data?.is_sub_admin ? (
                                                    permissions?.includes('customers_cart') ? (
                                                        <Route element={<CustomersCart />} path="/customers-cart" />
                                                    ) : (
                                                        <Route element={<PermissionDenied title='You have no Permission to view this page' />} path="/customers-cart" />
                                                    )
                                                ) : (
                                                    <Route element={<CustomersCart />} path="/customers-cart" />
                                                )
                                            }


                                            <Route element={<AdminProfile />} path="/settings" />

                                        </Routes>
                                    </div>
                                </Grid>
                            </Grid>
                        </>
                        :
                        <>
                            <center><p>This web app is not made for small screen devices</p></center>
                        </>
                }
            </div>
        </>
    )
}